/** @jsx jsx */
/* eslint-disable i18next/no-literal-string */
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';

import {PrimaryButton} from './Clickables';
import {P2, p2BaseStyle, P2Mono} from './Typography';
import FileFieldAttachment from '../../images/forms/file-field-attachment.no-inline.svg';
import TextAreaResizer from '../../images/forms/textarea-resizer.no-inline.svg';
import ArrowDown from '../../images/clickables/arrow_down.svg';
import useDetectOutsideClick from '../../helpers/hooks/useDetectOutsideClick';
import {hexToRgba} from '../../helpers/utils';
import {breakpoints, colors, fonts, fontWeights} from '../../styles/theme';


/*
 * Private Elements
 */
const baseInputPlaceholderStyle = css`
    font-family: ${fonts.sansSerif};
    color: ${colors.mediumGrey};
    font-size: 0.75rem;
    line-height: 40px;
`;

const baseInputStyles = css`
    padding: 0;
    border: none;
    border-bottom: 2px solid ${colors.white};
    border-radius: 0;
    background-color: inherit;
    color: inherit;
    line-height: 35px;
    min-height: 42px;
    font-family: ${fonts.sansSerif};
    font-size: 14px;
    width: 100%;

    &:focus {
        color: inherit;
        outline: none;
        border: none;
        border-bottom: 2px solid ${colors.white};
        transition: none;
        box-shadow: none;
        background-color: inherit;
    }

    &::placeholder {
        ${baseInputPlaceholderStyle}
    }

    &.form-control {
        height: 42px;
    }

    &.error {
        border-color: ${colors.red} !important;
    }
`;


/*
 * Public Elements
 */
const StyledFormError = styled.div`
    margin-top: 5px;

    ${P2Mono} {
        color: ${colors.red};
        font-size: 12px;
        line-height: 18px;
        font-weight: ${fontWeights.semiBold};
        margin: 0;
    }
`;

const FormError = ({messages = null}) => {
    if (!messages || !messages.length) {
        return null;
    }

    const formattedMessages = messages.map(message => (
        <P2Mono key={Math.random().toString(36).substring(2)}>{message}</P2Mono>
    ));
    return <StyledFormError className="form-error">{formattedMessages}</StyledFormError>;
};

FormError.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
};

FormError.defaultProps = {
    messages: null,
};


const Input = styled.input`
    ${baseInputStyles};
`;


const StyledInputFile = styled.label`
    ${baseInputStyles};

    &.disabled {
        background-color: #E9ECEF;
    }

    .placeholder {
        ${baseInputPlaceholderStyle};
    }

    background: url(${FileFieldAttachment}) no-repeat right center;

    input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -10;
    }
`;

/**
 * Custom input file
 *
 * @param {string} value - The value of the input
 * @param {function} onChange - The callback to run when the input changes
 * @param {string} id - The ID of the input
 * @param {string} placeholder - The placeholder to show in the input
 * @param {string} name - The name of the input
 * @param {string} className - The extra classes to add to the input
 * @param {boolean = false} disabled - Whether the form input is disabled or not
 * @param {boolean = false} required - Whether the form input is required or not
 * @param {string} acceptedFileExtensions - Comma-separated list of accepted file extensions (e.g.: ".pdf,.png")
 */
const InputFile = ({
    onChange = () => {},
    id = '',
    placeholder = '',
    name = '',
    className = '',
    disabled = false,
    required = false,
    acceptedFileExtensions = '',
}) => {
    const inputRef = useRef(null);
    const [placeholderText, setPlaceholderText] = useState(placeholder);
    const disabledClass = disabled ? 'disabled' : '';

    const _onChange = event => {
        setPlaceholderText(inputRef.current?.files[0]?.name || placeholder);
        onChange(event);
    };

    return (
        <StyledInputFile className={`input-file ${className} ${disabledClass}`} htmlFor={id}>
            <input
                type="file"
                name={name}
                id={id}
                required={required}
                disabled={disabled}
                onChange={_onChange}
                ref={inputRef}
                accept={acceptedFileExtensions}
            />
            <span className="placeholder">{placeholderText}</span>
        </StyledInputFile>
    );
};

InputFile.propTypes = {
    onChange: PropTypes.func,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    acceptedFileExtensions: PropTypes.string,
};

InputFile.defaultProps = {
    id: '',
    onChange: () => {},
    placeholder: '',
    name: '',
    className: '',
    disabled: false,
    required: false,
    acceptedFileExtensions: '',
};


const StyledInputRadio = styled.div`
    ${baseInputStyles};
    border: 0;
    height: auto;

    &.form-control {
        height: auto;
    }

    .radio-option {
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        justify-content: flex-start;
        align-items: baseline;

        label {
            ${p2BaseStyle};

            margin-left: 15px;
        }
    }
`;

/**
 * Custom input radio
 *
 * @param {string} id - The ID of the input
 * @param {string} name - The name of the input
 * @param {[{}]} options - The input options
 * @param {string} value - The value of the input
 * @param {function} onChange - The callback to run when the input changes
 * @param {string} className - The extra classes to add to the input
 * @param {boolean = false} disabled - Whether the form input is disabled or not
 * @param {boolean = false} required - Whether the form input is required or not
 */
const InputRadio = ({
    id,
    name,
    options,
    onChange = () => {},
    className = '',
    disabled = false,
    required = false,
}) => {
    const radioOptions = options.map((option, idx) => {
        const optionId = `${name}-${idx}`;
        return (
            <span className="radio-option" key={optionId}>
                <input
                    type="radio"
                    name={name}
                    id={optionId}
                    value={option.value}
                    onChange={event => { if (option.value.toString() === event.target.value) onChange(event); }}
                    required={required}
                    disabled={disabled}
                />
                <label htmlFor={optionId}>{option.name}</label>
            </span>
        );
    });

    return (
        <StyledInputRadio className={`input-radio ${className}`} id={id}>
            {radioOptions}
        </StyledInputRadio>
    );
};

InputRadio.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
};

InputRadio.defaultProps = {
    onChange: () => {},
    className: '',
    disabled: false,
    required: false,
};


const TextArea = styled.textarea`
    ${baseInputStyles};

    &::-webkit-resizer {
        background: url(${TextAreaResizer}) no-repeat;
    }
`;


const StyledSelect = styled.div`
    position: relative;

    .input-wrapper {
        ${Input} {
            cursor: inherit;
            padding-right: 35px;
            background-color: ${colors.transparent};
        }

        .caret {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 10px;
            margin: auto;
            z-index: 11;

            &.open {
                transform: scaleY(-1);

                * {
                    stroke: ${colors.darkPurple};
                }
            }
        }
    }

    .input-options-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        background-color: ${colors.white};
        color: ${colors.darkGrey};
        transition: max-height 0.3s ease-out;
        z-index: 10;

        &:not(.open) {
            max-height: 0;
        }

        .input-options-content {
            margin: 10px 0 15px;

            ${P2} {
                padding: 2.5px 35px 2.5px 20px;
                cursor: default;

                &:hover {
                    &:not(.default-option) {
                        background-color: ${hexToRgba(colors.darkPurple, 0.1)};
                        color: ${colors.darkPurple};
                        font-weight: ${fontWeights.medium};
                    }
                }

                &.default-option {
                    color: ${colors.darkPurple};
                    margin-bottom: 5px;
                }
            }
        }
    }
`;

/**
 * Custom select box
 *
 * @param {[{}]} options - List of the options to show in the select box
 * @param {string} defaultOption - The default, already selected, option in the select box (which can't be used)
 * @param {string} id - The ID of the element holding the value
 * @param {function} onSelect - Callback to run when an element is selected
 * @param {{}} initialOption - Initial selection option to display
 */
const Select = ({options, defaultOption, id = null, onSelect = () => {}, initialOption = {}}) => {
    const [selectedOption, setSelectedOption] = useState(initialOption);
    const [open, setOpen] = useState(false);
    const inputOptionsWrapper = useDetectOutsideClick(() => (open && setOpen(false)));

    /**
     * Set an option of the dropdown as selected
     *
     * @param {object} value - The option to set as selected when the function is called
     */
    const selectOption = value => () => {
        setSelectedOption(value);
        setOpen(false);
        onSelect(value);
    };

    let inputOptionsCss = null;
    const arrowClasses = ['caret'];
    const inputOptionsWrapperClasses = ['input-options-wrapper'];

    if (open) {
        arrowClasses.push('open');
        inputOptionsWrapperClasses.push('open');

        // Set the dropdown's max height to the "rendered" height of the element (the open/close animation is done with
        // this property)
        inputOptionsCss = {
            maxHeight: inputOptionsWrapper.current.scrollHeight,
        };
    }

    // Define the default element
    const styledDefaultOption = <P2 className="default-option" key="default-option">{defaultOption}</P2>;
    // Define the existing options
    const selectOptions = [styledDefaultOption].concat(options.map(option => (
        <P2 onClick={selectOption(option)} key={option.value}>{option.label}</P2>
    )));

    // Render the thing!
    return (
        <StyledSelect className="input-select">
            <div className="input-wrapper">
                <Input
                    type="text"
                    className="form-control"
                    placeholder={defaultOption}
                    value={selectedOption.value || ''}
                    onClick={() => setOpen(!open)}
                    role="listbox"
                    readOnly
                />
                <input type="hidden" id={id} value={selectedOption.key || ''} />
                <ArrowDown className={arrowClasses.join(' ')} onClick={() => setOpen(!open)} />
            </div>

            <div className={inputOptionsWrapperClasses.join(' ')} ref={inputOptionsWrapper} css={inputOptionsCss}>
                <div className="input-options-content">{selectOptions}</div>
            </div>
        </StyledSelect>
    );
};

Select.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    defaultOption: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    initialOption: PropTypes.object,
};

Select.defaultProps = {
    id: null,
    onSelect: () => {},
    initialOption: {},
};


const DefaultForm = styled.form`
    .form-group {
        margin-bottom: 32px;

        label {
            font-family: ${fonts.monospace};
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }

    .required {
        color: ${colors.darkPurple};

        &.small {
            font-size: 10px;
            line-height: 15px;
            font-family: ${fonts.monospace};
        }
    }

    .submit-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    ${PrimaryButton} {
        margin-top: 25px;
    }

    @media (min-width: ${breakpoints.md}) {
        ${PrimaryButton} {
            margin-top: 10px;
        }
    }
`;

export {
    FormError,
    DefaultForm,
    Input,
    InputFile,
    InputRadio,
    Select,
    TextArea,
};
